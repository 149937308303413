import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { IConfiguration } from '@microsoft/applicationinsights-core-js'
import { IConfig } from '@microsoft/applicationinsights-common'
import router from '@/router/index'
import store from '@/store/index'
import { frontendVersion } from '@/store/GlobalStore'

type AppInsightsConfig = IConfiguration & IConfig

export class AppInsightHandler {
  static install(vue, config: AppInsightsConfig): void {
    vue.prototype.$appInsightHandler = new AppInsightHandler(config)
  }

  private appInsights: ApplicationInsights
  private baseName = 'STYLER'

  public constructor(config: AppInsightsConfig) {
    console.log('start track', config.connectionString)
    config.enableAutoRouteTracking = true
    config.disableTelemetry = true
    config.enableDebug = true
    this.appInsights = new ApplicationInsights({ config })
    this.appInsights.loadAppInsights()
    this.setupPageTracking()
  }

  private trackingEnabled: boolean = null

  /**
   * Sets up tracking between pages
   */
  private setupPageTracking() {
    router.beforeEach((to, from, next) => {
      console.log('before each')
      this.trackingEnabled = this.checkTrackingEnabled()
      // Dynamically disable/enable telemetry tracking
      this.appInsights.config.disableTelemetry = !this.trackingEnabled
      if (this.trackingEnabled) {
        const name = this.baseName + ' / ' + to.name
        this.appInsights.startTrackPage(name)
      }
      next()
    })

    router.afterEach((route) => {
      if (this.trackingEnabled) {
        const name = this.baseName + ' / ' + route.name
        const url = location.protocol + '//' + location.host + route.fullPath
        const properties = {
          user_id: store.state.global.profileId,
          organisation_id: store.state.global.context.selection.organisation.id,
          app_version: frontendVersion,
          platform: 'web',
        }
        this.appInsights.stopTrackPage(name, url, properties)
        console.log('flush')
        this.appInsights.flush()
      }
    })
  }

  /**
   * Checks if tracking should be enabled. Returns true if both the user and the mandator have `is_productive` enabled
   */
  private checkTrackingEnabled(): boolean {
    if (!store.state.global.isLoggedIn) {
      return false
    }
    if (!store.state.global.profile) {
      return false
    }
    if (!store.state.global.profile.is_productive) {
      return false
    }
    if (!store.state.global.context.selection.organisation) {
      return false
    }
    if (!store.state.global.context.selection.organisation.is_productive) {
      return false
    }
    return true
  }
}
